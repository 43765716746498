import request from '@/utils/request'

/** 查询小区数据列表 */
export function listGarden(query) {
    return request({
        url: '/system/garden/list',
        method: 'get',
        params: query,
        headers: {
            isToken: false
        }
    })
}


// 查询小区数据详细
export function getGardenData(dataId) {
    return request({
        url: '/system/garden/' + dataId,
        method: 'get'
    })
}

// 查询所在支部下拉列表
export function listDangzhibuData() {
    return request({
        url: '/szdt/branch/anon/list?isDel=n',
        method: 'get'
    })
}
// 查询所在支部详细
export function getListDetail(dataId) {
    return request({
        url: '/szdt/branch/anon/' + dataId,
        method: 'get'
    })
}
// 查询所在支部组织架构
export function getListjiagouDetail(dataId) {
    return request({
        url: '/szdt/payroll/anon/list/' + dataId,
        method: 'get'
    })
}
// 获取党员个人信息
export function getListPersonDetail(dataId,unitId = 0) {
    return request({
        url: '/szdt/payroll/anon/' + dataId + "/" + unitId,
        method: 'get'
    })
}
// 党员积分信息
export function listIntegral(partyPayrollId, beginTime, endTime) {
    return request({
        url: '/szdt/detail/anon/list',
        method: 'get',
        params: {
            partyPayrollId,
            beginTime,
            endTime
        }
    })
}
// 党员缴费信息
export function listPayment(partyPayrollId, beginTime, endTime) {
    return request({
        url: '/szdt/dues/anon/list',
        method: 'get',
        params: {
            partyPayrollId,
            beginTime,
            endTime
        }
    })
}
// 打卡记录
export function listPunch(partyPayrollId, beginTime, endTime) {
    return request({
        url: '/szdt/sign/anon/list',
        method: 'get',
        params: {
            partyPayrollId,
            beginTime,
            endTime
        }
    })
}
