import request from '@/utils/request'

// 查询所在支部下拉列表
export function listDangzhibuData() {
    return request({
        url: '/szdt/wjdt/grid/list',
        method: 'get'
    })
}

// 查询所在支部组织架构
export function getListjiagouDetail(dataId) {
    return request({
        url: '/szdt/wjdt/member/list?gridId=' + dataId,
        method: 'get'
    })
}